import React from 'react';
import classNames from 'classnames';
import * as MaterialDesignIcons from 'react-icons/md';
import * as Typicons from 'react-icons/ti';
import * as FontAwesome from 'react-icons/fa';

import ReactTooltip from 'react-tooltip';

/* TODO it's only rounded version, adapt to basic icon use also */
const Icon = ({
  name = 'MdFileDownload',
  iconProps = { size: '1.5rem', color: 'text-blue' },
  oval = false,
  className = '',
  handler,
  id,
  tooltip,
}) => {
  let Icon = MaterialDesignIcons[name] || Typicons[name] || FontAwesome[name];
  return (
    <div
      className={classNames(className, oval ? 'icon-container' : '')}
      onClick={handler}
      data-testid="icon"
    >
      <Icon
        size={iconProps.size}
        className={classNames('inline', iconProps.color)}
        data-for={tooltip ? id : null}
        data-tip
      />
      {tooltip ? (
        <ReactTooltip id={id} place="right" type="info" effect="solid">
          <span>{tooltip}</span>
        </ReactTooltip>
      ) : null}
    </div>
  );
};

export default Icon;
