import { useQuery, useMutation, queryCache } from 'react-query';
import { client } from './apiClient';

const defaultMutationOptions = {
  onError: (err, variables, recover) =>
    typeof recover === 'function' ? recover() : null,
  onSettled: () => queryCache.refetchQueries('housingCompanies'),
  useErrorBoundary: false,
  throwOnError: true,
};

function readCompanies() {
  return client('api/1.0/housings/housing-company', { api: 'data' });
}

function createCompany({
  company: name,
  address,
  postCode: post_code,
  city,
  country,
}) {
  return client('api/1.0/housings/housing-company', {
    body: { name, address, post_code, city, country },
    api: 'data',
  });
}

function useHousing({ onSuccess, ...options } = {}) {
  const { data: housingCompanies } = useQuery({
    queryKey: 'housingCompanies',
    queryFn: readCompanies,
    ...options,
  });

  return housingCompanies ?? [];
}

function useCreateHousing(options) {
  return useMutation(createCompany, {
    ...defaultMutationOptions,
    ...options,
  });
}

/* Following function allows housing managers to read tenants list*/
function useTenants({ onSuccess, ...options } = {}) {
  const { data: tenants } = useQuery({
    queryKey: 'tenants',
    queryFn: readTenants,
    ...options,
  });

  return tenants ?? [];
}

function readTenants() {
  return client('api/1.0/housing_manager/tenant', { api: 'data' });
}

function useUpdateHousingCompanyDetails(data, id) {
  return client(`api/1.0/housings/housing-company/${id}`, {
    api: 'data',
    method: 'PUT',
    body: data,
  }).then(
    (res) => res,
    (error) => error
  );
}

function getHousingMapping(userGroup) {
  return client(`/api/1.0/${userGroup}/housing-mapping`, {
    api: 'data',
  });
}

export {
  useHousing,
  useCreateHousing,
  useTenants,
  useUpdateHousingCompanyDetails,
  getHousingMapping,
};
