import * as auth from './authClient';
import * as tenant from './tenant';
import * as housing from './housing';
/* Fetches bootstrap data; user, me and contract */
async function bootstrapAppData() {
  let appData = { user: null, me: null, contract: null };

  if (auth.isLoggedIn()) {
    const [user, me] = await Promise.all([auth.getUser(), auth.getMe()]);
    let housingMapping = { results: [] };
    await Promise.resolve(
        housing.getHousingMapping(me.group),
    ).then((result) => {
        housingMapping = result;
    }).catch((err) => console.log(err));

    if (me.group === 'tenant') {
      const contract = await tenant.getContract();
      appData['contract'] = contract;
    }
    appData['user'] = user;
    appData['me'] = me;
    appData['housingMapping'] = housingMapping?.results;
  }
  return appData;
}

export { bootstrapAppData };
